import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomText } from "./CustomText/CustomText";
import LocationImage from "../../assets/images/Location_image.jpg";
import message from "../../assets/icons/message.png";
import phone from "../../assets/icons/phone.png";
import location from "../../assets/icons/location.png";
import {
  setName,
  setEmail,
  setPhone,
  setMessage,
} from "../../constants/Redux/Slice/contactusSlice";

function Contactus() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  async function handleSubmit() {
    dispatch(setName(customerName));
    dispatch(setEmail(customerEmail));
    dispatch(setPhone(customerPhone));
    dispatch(setMessage(customerMessage));
    try {
      const response = await fetch(
        "http://api-uat.moversly.com/api/v1/order/send-customer-details-to-sqs",
        //"http://localhost:8080/api/v1/order/send-customer-details-to-sqs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();

      console.log("Success:", responseData);
      navigate("/thankyou");
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const payload = {
    Enquiry: "",
    name: customerName,
    email: customerEmail,
    phone: customerPhone,
    message: customerMessage,
  };
  return (
    <div className="contactus bg-slate-300 pt-2">
      <div className="flex w-full justify-center text-center mt-4">
        <CustomText
          label="Contact us"
          styles="font-extrabold uppercase text-gray-700 aboutus-header"
          underlineStyle="border-t border-teal-500 border-solid aboutus-header-underline"
        />
      </div>
      <div className="contactus-flex-container">
        <div className="flex flex-row">
          <div className="contactus-image-box">
            <img
              className="contactus-image"
              src={LocationImage}
              alt="Location Image"
            />
          </div>
          <div className="contactus-input">
            <input
              type="text"
              id="name"
              name="name"
              className="rounded-bl-lg rounded-tl-lg rounded-br-lg rounded-tr-lg block  border border-gray-300 font-poppins font-normal rounded focus:outline-none focus:border-teal-500 contactus-input"
              placeholder="Enter Your Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
            <input
              type="text"
              id="email"
              name="email"
              className="rounded-bl-lg rounded-tl-lg rounded-br-lg rounded-tr-lg block  border border-gray-300 font-poppins  font-normal rounded focus:outline-none focus:border-teal-500 contactus-input"
              placeholder="Enter Your Email"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
            />
            <input
              type="text"
              id="phone"
              name="phone"
              className="rounded-bl-lg rounded-tl-lg rounded-br-lg rounded-tr-lg block  border border-gray-300 font-poppins font-normal rounded focus:outline-none focus:border-teal-500 contactus-input"
              placeholder="Phone Number "
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
            />
            <input
              type="text"
              id="message"
              name="message"
              className="rounded-bl-lg rounded-tl-lg rounded-br-lg rounded-tr-lg block  border border-gray-300 font-poppins font-normal rounded focus:outline-none focus:border-teal-500 contactus-input"
              placeholder="Message"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
            <div className="contactus-button">
              <button
                id="submit"
                type="submit"
                onClick={handleSubmit}
                className="pr-2 pb-2 pl-2 pt-2 inline-block body rounded-xl bg-teal-500 text-white hover:bg-teal-600 focus:outline-none focus:bg-teal-600 font-poppins font-normal"
              >
                Submit
              </button>
              <div className="contactus-thankyou font-poppins font-normal text-teal-500 lowercase">
                Thankyou for contacting us! You wil get an Answer soon
              </div>
            </div>
          </div>
        </div>

        <div className="contactus-address">
          <div className=" body rounded-3xl flex-shrink-0 ml-5">
            <div className="mb-1">
              <img
                className="inline-block contactus-icon"
                src={message}
                alt="message"
              ></img>
              <div className="inline-block ml-3">
                contact@apacrelocation.com
              </div>
            </div>
            <div className="mb-1">
              <img
                className="inline-block contactus-icon"
                src={phone}
                alt="phone"
              ></img>
              <div className="inline-block ml-3">+65 6520 1914</div>
            </div>
            <div className="mb-6 flex items-center">
              <img
                className="inline-block contactus-icon"
                src={location}
                alt="location"
              />
              <div className="inline-block ml-3">
                <p className="mb-1">2, Ang Mo Kio Street</p>
                <p className="mb-1">64, #02-03A, Econ Building,</p>
                <p>Singapore 569084</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom mt-3 h-20 bg-teal-950 flex-shrink-0"></div>
    </div>
  );
}

export default Contactus;
