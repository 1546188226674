import React, { useState } from "react";
export default function TextField({
  placeHolder,
  id,
  name,
  label,
  styles,
  labelStyle,
  value,
  onChange,
}) {
  return (
    <input
      className={`rounded-bl-lg rounded-tl-lg rounded-br-lg rounded-tr-lg block mt-4 p-2 border border-gray-300 font-poppins font-normal rounded focus:outline-none focus:border-teal-500 ${styles}`}
      type="text"
      id={id}
      name={name}
      placeholder={placeHolder}
      value={value}
      onChange={onChange}
    />
  );
}
