import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  ArrowDropDown,
  ArrowDropDownOutlined,
  ArrowDropUp,
  ArrowUpwardOutlined,
  SearchOutlined,
  Send,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Button from "../../sharedComponents/Button/Button";
import {
  setServiceName,
  setPostCode,
} from "../../../constants/Redux/Slice/searchbarSlice";
import Box from "@mui/material/Box";
import CustomButton from "../../sharedComponents/CustomButton/CustomButton";
import search from "../../../assets/images/search.png";
import postcode from "../../../assets/images/postcode.png";
import dropdown from "../../../assets/images/downarrow.png";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

export const Searchbar = () => {
  const dispatch = useDispatch();

  const { ServiceName, PostCode } = useSelector((state) => state.searchbar);
  console.log("ServiceName = ", ServiceName);
  const [isOpen, setISOpen] = useState(false);
  const navigate = useNavigate();
  const [postcode, setPostcode] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const handleServiceClick = (service) => {
    setSelectedService(service);
    //setIsOpen(false);
  };
  useEffect(() => {
    dispatch(setServiceName(selectedService));
  }, [selectedService]);
  console.log(postcode);
  return (
    <div className="searchbar-style">
      <div>
        <button
          className="searchbar-button"
          onClick={() => setISOpen((prev) => !prev)}
        >
          <div className="search-icon">
            <SearchOutlined />
          </div>
          <p className="search-select-service">
            {selectedService || "Select Service"}
          </p>
          {!isOpen ? (
            <ArrowDropDown className="" />
          ) : (
            <ArrowDropUp className="" />
          )}
        </button>
        {isOpen && (
          <div className="flex flex-row bg-white absolute rounded-[10px] pt-2 pb-2 mt-2 searchbar-dropdown">
            <div className=" ml-5 flex font-poppins font-medium w-full searchbar-menuitem">
              <ul className="space-y-3">
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Professional Cleaning")}
                >
                  Professional Cleaning
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Deep Cleaning")}
                >
                  Deep Cleaning
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Handyman")}
                >
                  Handyman
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() =>
                    handleServiceClick("Outdoor Decking Repair & Polishing")
                  }
                >
                  Outdoor Decking Repair & Polishing
                </li>
              </ul>
            </div>
            <div className="  ml-10 flex font-poppins font-medium w-full searchbar-menuitem">
              <ul className="space-y-3">
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Painting")}
                >
                  Painting
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Plumbing")}
                >
                  Plumbing
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Moving")}
                >
                  Moving
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() =>
                    handleServiceClick("Marble Repairing & Polishing")
                  }
                >
                  Marble Repairing & Polishing
                </li>
              </ul>
            </div>
            <div className="  ml-10 flex  font-poppins font-medium w-full searchbar-menuitem">
              <ul className="space-y-3">
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Electrician")}
                >
                  Electrician
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() => handleServiceClick("Carpentry")}
                >
                  Carpentry
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() =>
                    handleServiceClick("Floor Repairing & Polishing")
                  }
                >
                  Floor Repairing & Polishing
                </li>
                <li
                  className="hover:font-bold cursor-pointer transition hover:scale-110"
                  onClick={() =>
                    handleServiceClick("Timber / Parquet Repair & Polishing")
                  }
                >
                  Timber / Parquet Repair & Polishing
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="send-icon">
        <Send />
      </div>
      <div className="searchbar-input">
        <input
          type="text"
          placeholder="Postcode"
          className="border-transparent outline-transparent"
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
        />
      </div>
      <div className="">
        <Button
          label="BOOK NOW"
          styles="searchbar-button-booknow"
          onClick={handleBookNow}
        />
      </div>
    </div>
  );

  function handleBookNow() {
    //navigate("/bookingRequirement");
    dispatch(setPostCode(postcode));
    if (selectedService && postcode) {
      //dispatch(setServiceName({ serviceName: selectedService }));
      navigate("/bookingRequirement");
    } else {
      alert("Please select a service and enter a postcode");
    }
  }
};
