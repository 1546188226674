import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import Button from "../../sharedComponents/Button/Button";

export const ProfessionalCleaningDetails = () => {
  const { CleaningFrequency, HoursperSession } = useSelector(
    (state) => state.cleaning
  );
  const { PropertyType } = useSelector((state) => state.propertyType);
  const { BookingDate, BookingTime } = useSelector((state) => state.dateTime);
  const { TotalPrice } = useSelector((state) => state.pricing);
  const navigate = useNavigate();
  const { ServiceName, Postcode } = useSelector((state) => state.searchbar);
  return (
    <div>
      <div className="flex flex-col">
        <div className="bg-white rounded-xl ml-10 mr-10 mt-16 shadow-xl pb-2 ">
          <CustomText
            label="Cleaning Services"
            labelStyle="font-semibold ml-3"
            styles="mt-2 ml-2"
          />
          <CustomText
            label="One-off or periodic cleaning for your home done by professionals."
            labelStyle="mt-3 "
            styles="mt-2 ml-6"
          />
          <CustomText
            label="What’s included & how it works ?"
            labelStyle="text-teal-500 mt-3 ml-4 text-[12px]"
            styles="mt-3 ml-2"
          />
        </div>
        <div className="bg-white ml-10 mr-10 rounded-xl mt-10 shadow-xl text-center pb-2">
          <CustomText
            label={`${Postcode}, Singapore`}
            labelStyle="text-xl font-semibold"
            styles="mt-2 ml-4"
          />
        </div>
        <div className="bg-white ml-10 mr-10 rounded-xl mt-10 shadow-xl pb-4">
          <div>
            <CustomText
              label="Your Booking"
              labelStyle="text-xl font-semibold mt-5 ml-5"
              styles="mt-5"
              underlineStyle="w-full h-[1px] bg-black mt-1"
            />
          </div>
          <div className="flex flex-row">
            <div>
              <ul className="ml-5 space-y-4 font-normal mt-5">
                <li>Cleaning Frequency</li>
                <li>Starting date</li>
                <li>Booking time</li>
                <li>Property type</li>
                <li>Hours per session</li>
              </ul>
            </div>
            <div>
              <ul className="ml-10 space-y-4 font-medium mt-5">
                <li>{CleaningFrequency || "-"}</li>
                <li>{BookingDate || "-"}</li>
                <li>{BookingTime || "-"}</li>
                <li>{PropertyType || "-"}</li>
                <li>{HoursperSession || "-"}</li>
              </ul>
            </div>
          </div>

          <div className="w-full h-[0.5px] bg-black mt-5"></div>
          {/* <div className="flex flex-row">
        <CustomText
          label="Starting From"
          labelStyle="text-xl font-semibold mt-5"
          styles="mt-1 ml-5"
        />
        <CustomText
          label={`SG $${TotalPrice}`}
          labelStyle=" text-xl font-semibold mt-5"
          styles="mt-1 ml-5"
        />
      </div> */}

          <div className="text-center">
            <Button
              label="Continue"
              onClick={handleContinue}
              styles=" text-white  font-semibold mt-20 h-12 bg-teal-500 outline outline-2 rounded-[5px] outline-black hover:text-black hover:bg-teal-300 pl-6 pr-6 pt-2 pb-2"
            />
          </div>
        </div>
      </div>
    </div>
  );

  function handleContinue() {
    navigate("/bookingConfirmation");
  }
};
