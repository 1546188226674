import React from "react";
import { Item } from "./Item";

export const ItemList = ({ items }) => {
  return (
    <div>
      {items.map((item) => (
        <Item key={item.id} id={item.id} name={item.name} count={item.count} />
      ))}
    </div>
  );
};
