import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomText } from "../components/sharedComponents/CustomText/CustomText";
import Button from "../components/sharedComponents/Button/Button";

export const ThankyouPage = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/");
  }

  return (
    <div className="w-full h-full bg-slate-300 flex flex-col">
      <div className=" pt-80">
        <CustomText
          label="Thank you for Submitting your Response !"
          labelStyle="font-medium"
          styles="text-center text-2xl"
        />
      </div>
      <div className="text-center pb-96">
        <Button
          label="BACK TO HOME"
          onClick={handleClick}
          styles="text-white font-semibold mt-20 h-12 bg-teal-500 outline outline-2 rounded-[5px] outline-black hover:text-black hover:bg-teal-300 pl-6 pr-6 pt-2 pb-2"
        />
      </div>
    </div>
  );
};
