import React from 'react'
import { CustomText } from '../components/sharedComponents/CustomText/CustomText'
import TextField from '../components/sharedComponents/CustomText/TextField'
import Button from '../components/sharedComponents/Button/Button'
import { useNavigate } from "react-router-dom";

export default function Login() {
    const navigation = useNavigate();
    function handleSignUp() {
      navigation("/singUp");
    }
  return (
    <div className="w-[550px] h-[400px] ml-[80px] bg-white">
    <div className="flex ml-[50px] pt-[30px]">
      <CustomText
        styles=""
        label="Login"
        labelStyle="pl-[70px] font-normal text-[16px] text-slate-400"
        underlineStyle="border-t border-teal-500 border-solid border-2 my-2 w-[200px] ml-0"
      />
      <CustomText
        styles="pl-[70px]"
        label="Sign Up"
        onClick={handleSignUp}
        labelStyle="pl-[70px] font-normal text-[16px] text-slate-400"
        underlineStyle="border-t border-white border-solid border-2 my-2 w-[200px] ml-0"
      />
    </div>
    <div className="ml-[10px] mr-[10px]">
      <TextField
        styles="w-full"
        placeHolder="Email"
        id="email"
        name="email"
      />
      <TextField
        styles="w-full"
        placeHolder="Password"
        id="password"
        name="password"
      />
      <Button
        label="Submit"
        styles="mt-[10px] w-full text-white h-[40px] justify-center px-6 bg-teal-500 hover:bg-teal-400 hover:scale-110"
        // onClick={handleBookNow}
      />
    </div>
  </div>
  )
}
