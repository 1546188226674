import logo from "./logo.svg";
import BookingConfirmation from "./screen/BookingConfirmation";
import BookingRequirement from "./screen/BookingRequirement";
import YourDetails from "./screen/YourDetails";
import Home from "./screen/Home";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./screen/SignUp";
import Login from "./screen/Login";
import { ThankyouPage } from "./screen/ThankyouPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="bookingRequirement" element={<BookingRequirement />} />
      <Route path="bookingConfirmation" element={<BookingConfirmation />} />
      <Route path="yourDetails" element={<YourDetails />} />
      <Route path="thankyou" element={<ThankyouPage />} />
      <Route path="singUp" element={<SignUp />} />
      <Route path="login" element={<Login />} />
      <Route
        path="bookingRequirement/:service/:postcode"
        element={<BookingRequirement />}
      />
      <Route
        path="bookingConfirmation/:service/:postcode/:queryParams"
        element={<BookingConfirmation />}
      />
    </Routes>
  );
}

export default App;
