import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyType } from "../../../constants/Redux/Slice/propertyTypeSlice";
import {
  setPickupPropertyType,
  setDropoffPropertyType,
} from "../../../constants/Redux/Slice/movingSlice";

export const DropdownBox = ({
  label,
  boxStyle,
  buttonStyle,
  selectedService,
  type,
}) => {
  const [isOpen, setISOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const newOptions = getOptionsBasedOnService(selectedService);

    setOptions(newOptions);
  }, [selectedService]);

  const getOptionsBasedOnService = (service) => {
    if (service === "Professional Cleaning") {
      return ["Condominium", "HDB", "Landed Property", "Shophouse home"];
    } else if (service === "Deep Cleaning") {
      return ["Condominium", "HDB", "Landed Property", "Shophouse home"];
    }
    return [];
  };

  const handleOptionSelect = (option, type) => {
    switch (type) {
      case "normal":
        dispatch(setPropertyType(option));
        break;
      case "pickup":
        dispatch(setPickupPropertyType(option));
        break;
      case "dropoff":
        dispatch(setDropoffPropertyType(option));
        break;
      default:
        console.log("NO option");
    }

    setSelectedOption(option);
    setISOpen(false);
  };

  return (
    <div
      className={`flex flex-row items-center justify-items-center py-[2px]  bg-white rounded-[10px] ${boxStyle}`}
    >
      <div>
        <button
          className={`flex bg-white items-center justify-between rounded-[10px] border-2 border-slate-400  active:text-white ${buttonStyle}`}
          onClick={() => setISOpen((prev) => !prev)}
        >
          <p className="ml-10 text-slate-400">{selectedOption || label}</p>
          {!isOpen ? <ArrowDropDown /> : <ArrowDropUp />}
        </button>
        {isOpen && (
          <div className="absolute bg-white mt-1 items-center p-2 rounded-[10px] shadow-lg">
            {options.map((option) => (
              <div
                key={option}
                className={`cursor-pointer py-1 hover:bg-teal-500 rounded-[10px] ${
                  selectedOption === option ? "bg-teal-200" : ""
                }`}
                onClick={() => handleOptionSelect(option, type)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
