import React from "react";

export const CustomText = ({ label, label2, styles, underlineStyle, labelStyle, labelStyle2, onClick }) => {
  return (
    <div className={`font-poppins ${styles}`} onClick={onClick}>
      <span className={`${labelStyle}`}>{label}</span>
      <span className={`${labelStyle2}`}>{label2}</span>
      <div className={`${underlineStyle}`}></div>
    </div>
  );
};
