import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/style.css";
import "../../css/bootstrap.css";
import "../../css/responsive.css";
import logo from "../../assets/images/logo.png";
import {
  Close,
  Handyman,
  KeyboardArrowRightRounded,
  KeyboardArrowUpRounded,
  Menu,
} from "@mui/icons-material";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const openDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          {/* <img src={logo} alt="logo" width="64" className="navbar-logo" /> */}
          <Link to="/" className="navbar-logo">
            APAC
            <Handyman />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <button>{click ? <Close /> : <Menu />}</button>
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <div className="nav-links" onClick={openDropdown}>
                Our Services
                {/* {click ? <ArrowDropDown /> : <ArrowDropDown />} */}
                {dropdown ? (
                  <KeyboardArrowUpRounded />
                ) : (
                  <KeyboardArrowRightRounded />
                )}
                {dropdown && (
                  <div className="navbar-dropdown-mobile">
                    <div className="navbar-flex-container-mobile">
                      <div className="dropdown-container-mobile">
                        <ul>
                          <li className="dropdown-item-mobile">
                            Choose from our popular services
                          </li>
                        </ul>
                      </div>
                      <div className="dropdown-container-mobile">
                        <ul>
                          <li className="dropdown-header-mobile">
                            Most popular
                          </li>
                          <li className="dropdown-item-mobile">
                            Professional Cleaning
                          </li>
                          <li className="dropdown-item-mobile">
                            Deep Cleaning
                          </li>
                          <li className="dropdown-item-mobile">Handyman</li>
                          <li className="dropdown-item-mobile">Moving</li>
                        </ul>
                      </div>
                      <div className="dropdown-container-mobile">
                        <ul>
                          <li className="dropdown-header-mobile">
                            Other Services
                          </li>
                          <li className="dropdown-item-mobile">Carpentry</li>
                          <li className="dropdown-item-mobile">Electrician</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Become a Partner
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/  " className="nav-links" onClick={closeMobileMenu}>
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="nav-outerbox"></div>
      {dropdown && (
        <div className="navbar-dropdown">
          <div className="navbar-flex-container">
            <div className="dropdown-container">
              <ul>
                <li className="dropdown-header">Our Services</li>
                <li className="dropdown-item">
                  Choose from our popular services
                </li>
              </ul>
            </div>
            <div className="dropdown-container">
              <ul>
                <li className="dropdown-header">Most popular</li>
                <li className="dropdown-item">Professional Cleaning</li>
                <li className="dropdown-item">Deep Cleaning</li>
                <li className="dropdown-item">Handyman</li>
                <li className="dropdown-item">Moving</li>
              </ul>
            </div>
            <div className="dropdown-container">
              <ul>
                <li className="dropdown-header">Other Services</li>
                <li className="dropdown-item">Carpentry</li>
                <li className="dropdown-item">Electrician</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
