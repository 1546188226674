import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateTimeBox from "../Booking/DateTimeBox";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import { DropdownBox } from "../Booking/DropdownBox";
import {
  setMovingType,
  setPickupAddress,
  setDropoffAddress,
} from "../../../constants/Redux/Slice/movingSlice";

export const Moving = () => {
  const [selectedButton, setSelectedButton] = useState(null);
  const dispatch = useDispatch();
  const [pickup, setPickup] = useState("");
  const [dropoff, setDropoff] = useState("");
  dispatch(setPickupAddress(pickup));
  dispatch(setDropoffAddress(dropoff));

  const handleButtonClick = (buttonId) => {
    if (selectedButton === buttonId) {
      // If the clicked button is already selected, deselect it
      setSelectedButton(null);
    } else {
      // If a different button is clicked, select it
      setSelectedButton(buttonId);
      if (buttonId === 1) {
        const label = "Home Moving";
        dispatch(setMovingType(label));
      } else {
        const label = "Commercial Moving";
        dispatch(setMovingType(label));
      }
    }
  };
  return (
    <div>
      <div>
        <CustomText
          label="Moving date & time"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-20 ml-1 mr-1"
        />
      </div>

      <div>
        <DateTimeBox
          label="Select date & time"
          boxStyle=" mt-2 ml-1 mr-1 booking-req-dropdown"
        />
      </div>
      <div>
        <CustomText
          label="Which service do you require?"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-5 ml-1 mr-1"
        />
      </div>
      <div className="mt-2 ml-5">
        <button
          onClick={() => handleButtonClick(1)}
          className={`button2 ${selectedButton === 1 ? "selected" : ""}`}
        >
          Home Moving
        </button>
        <button
          onClick={() => handleButtonClick(2)}
          className={`button2 ${selectedButton === 2 ? "selected" : ""}`}
        >
          Commercial Moving
        </button>
      </div>
      <div>
        <CustomText
          label="Pick up location"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-5 ml-1 mr-1"
        />
        <input
          type="text"
          className="ml-8 mt-2  h-[40px] outline outline-2 w-[270px] outline-slate-400 rounded-[5px] justify-center"
          placeholder="Pick up address"
          style={{ textIndent: "20px" }}
          value={pickup}
          onChange={(e) => setPickup(e.target.value)}
        />
      </div>
      <div>
        <CustomText
          label="Type of property"
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div>
        <DropdownBox
          label="Select property type"
          boxStyle=" h-12 mt-2 ml-1 mr-1 booking-req-dropdown"
          buttonStyle=" h-12"
          selectedService="Deep Cleaning"
          type="pickup"
        />
      </div>
      <div>
        <CustomText
          label="Drop off location"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-5 ml-1 mr-1"
        />
        <input
          type="text"
          className="ml-8 mt-2  h-[40px] outline outline-2 w-[270px] outline-slate-400 rounded-[5px] justify-center"
          placeholder="Drop off address"
          style={{ textIndent: "20px" }}
          value={dropoff}
          onChange={(e) => setDropoff(e.target.value)}
        />
      </div>
      <div>
        <CustomText
          label="Type of property"
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div>
        <DropdownBox
          label="Select property type"
          boxStyle=" h-12 mt-2 ml-1 mr-1 booking-req-dropdown"
          buttonStyle=" h-12"
          selectedService="Deep Cleaning"
          type="dropoff"
        />
      </div>
    </div>
  );
};
