import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";

function CustomButton({
  bg,
  label,
  customStyles,
  icon,
  onClick,
  textColor = "text-white",
  id,
  py,
  loading = false,
  type = "submit",
  disabled = false,
  variant = "contained",
}) {
  return (
    <Button
      // component="button"
      // type={type}
      id={id}
      disabled={disabled}
      variant={variant === "contained" ? "contained" : "outlined"}
      className={`${bg}   ${customStyles} ${
        textColor ? textColor : "text-white"
      } w-full capitalize font-[550] rounded-lg outline-none ${
        py && py
      } shadow-none`}
      onClick={onClick}
    >
      <Typography className={`${icon && "ml-3"}`} variant="body2">
        {/* <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
          Processing...
        </svg> */}

        {loading ? "Loading ..." : label}
      </Typography>
    </Button>
  );
}

export default CustomButton;
