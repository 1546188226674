import React from "react";

const ServiceOptions = ({
  styles,
  label,
  labelStyles,
  content,
  contentStyle,
  imageUrl,
  imageStyle,
}) => (
  <div className="service-option">
    <button
      type="button"
      className={`font-poppins font-medium outline-none ${styles} transition hover:-translate-y-1 hover:scale-110 duration-300`}
    >
      {imageUrl && (
        <img src={imageUrl} alt={label} className={`${imageStyle}`} />
      )}
      <p className={`${labelStyles}`}>{label}</p>
      <p className={`${contentStyle}`}>{content}</p>
    </button>
  </div>
);

export default ServiceOptions;
