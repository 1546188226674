import { ArrowDropDown, ArrowDropUp, TextFields } from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import {
  setBookingDate,
  setBookingTime,
} from "../../../constants/Redux/Slice/dateTimeSlice";
import {
  setInspectionDate,
  setInspectionTime,
} from "../../../constants/Redux/Slice/movingSlice";

const DateTimeBox = ({ label, buttonStyle, boxStyle, type }) => {
  const [isOpen, setISOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };
  const [selectedTime, setSelectedTime] = useState(null);

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };
  console.log("this is type in date", type);
  if (type === "inspection") {
    dispatch(setInspectionDate(dayjs(selectedDate).format("DD-MM-YYYY")));
    dispatch(setInspectionTime(dayjs(selectedTime).format("HH:mm")));
  } else {
    dispatch(setBookingTime(dayjs(selectedTime).format("HH:mm")));
    dispatch(setBookingDate(dayjs(selectedDate).format("DD-MM-YYYY")));
  }

  return (
    <div
      className={`flex flex-row items-center justify-items-center py-[2px] bg-white rounded-[10px] ${boxStyle}`}
    >
      <div>
        <button
          className={`flex h-12 bg-white items-center justify-between rounded-[10px] border-2 border-slate-400  ${buttonStyle}`}
          onClick={() => setISOpen((prev) => !prev)}
        >
          <p className="ml-10 text-slate-400">{label}</p>
          {!isOpen ? <ArrowDropDown /> : <ArrowDropUp />}
        </button>
        {isOpen && (
          <div className="absolute bg-white items-center p-2 rounded-[10px] shadow-lg">
            <div className="ml-4 mt-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Select your date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextFields {...params} />}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="ml-4 mt-8">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <TimePicker
                    label="Select your time "
                    value={selectedTime}
                    onChange={handleTimeChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateTimeBox;
