import * as React from "react";
import Header from "../components/sharedComponents/Header";
import { CustomText } from "../components/sharedComponents/CustomText/CustomText";
import Button from "../components/sharedComponents/Button/Button";
import { DropdownBox } from "../components/pageComponents/Booking/DropdownBox";
import Arrow from "../assets/icons/arrow icon.png";
import SignUp from "./SignUp";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "../components/sharedComponents/CustomText/TextField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  setName,
  setAddress,
  setEmail,
  setPhone,
} from "../constants/Redux/Slice/customerSlice";
import { ReportGmailerrorred } from "@mui/icons-material";
import CustomizedDialogs from "../components/sharedComponents/Dialoguebox";
import RenderIf from "../components/sharedComponents/RenderIf";
import { ProfessionalCleaningDetails } from "../components/pageComponents/YourDetails/ProfessionalCleaningDetails";
import { DeepCleaningDetails } from "../components/pageComponents/YourDetails/DeepCleaningDetails";
import { MovingDetails } from "../components/pageComponents/YourDetails/MovingDetails";
import { HandymanDetails } from "../components/pageComponents/YourDetails/HandymanDetails";
import { PaintingDetails } from "../components/pageComponents/YourDetails/PaintingDetails";
import { ElectricianDetails } from "../components/pageComponents/YourDetails/ElectricianDetails";
import { PlumbingDetails } from "../components/pageComponents/YourDetails/PlumbingDetails";
import { FloorRepairDetails } from "../components/pageComponents/YourDetails/FloorRepairDetails";
import { MarbleRepairDetails } from "../components/pageComponents/YourDetails/MarbleRepairDetails";
import { OutdoorDeckingDetails } from "../components/pageComponents/YourDetails/OutdoorDeckingDetails";
import { CarpentryDetails } from "../components/pageComponents/YourDetails/CarpentryDetails";
import { TimberParquetDetails } from "../components/pageComponents/YourDetails/TimberParquetDetails";

export default function YourDetails() {
  const data = {
    Name: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let payload = {};
  function handleOnChangePhone(params) {
    console.log("params", params);
    dispatch(setPhone(params));
  }
  async function handleSubmit() {
    try {
      dispatch(setName(customerName));
      dispatch(setAddress(customerAddress));
      dispatch(setEmail(customerEmail));
      //dispatch(setPhone(PhoneNumber));
      const response = await fetch(
        "http://api-uat.moversly.com/api/v1/order/send-customer-details-to-sqs",
        //"http://localhost:8080/api/v1/order/send-customer-details-to-sqs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();

      console.log("Success:", responseData);

      navigate("/thankyou");
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const [inputValue, setInputValue] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");

  const { CleaningFrequency, HoursperSession } = useSelector(
    (state) => state.cleaning
  );
  const {
    PropertySize,
    MattressSteamCleaningSingle,
    MattressSteamCleaningQueen,
    MattressSteamCleaningKing,
    OvenCleaning,
    DegreasingKitchen,
    SteamCleanKitchenFloor,
    FridgeCleaning,
    RemovalofTapeStains,
  } = useSelector((state) => state.deepCleaning);
  const {
    MovingType,
    PickupAddress,
    PickupPropertyType,
    DropoffAddress,
    DropoffPropertyType,
    ListofItems,
    InspectionDate,
    InspectionTime,
  } = useSelector((state) => state.moving);
  const { SelectedServices } = useSelector((state) => state.handyman);
  const { TotalPrice } = useSelector((state) => state.pricing);
  const { Name, Phone, Email, Address } = useSelector(
    (state) => state.customer
  );
  const { BookingTime, BookingDate } = useSelector((state) => state.dateTime);
  const { ServiceDetails } = useSelector((state) => state.serviceDetails);
  const { PropertyType } = useSelector((state) => state.propertyType);
  const { ServiceName, Postcode } = useSelector((state) => state.searchbar);
  const { HavePets } = useSelector((state) => state.pets);
  const { AdditionalPreference } = useSelector((state) => state.addOns);

  if (ServiceName === "Professional Cleaning") {
    payload = {
      name: customerName,
      phone: Phone,
      email: customerEmail,
      address: customerAddress,
      service_name: ServiceName,
      postcode: Postcode,
      frequency: CleaningFrequency,
      no_of_hours: HoursperSession,
      date: BookingDate,
      time: BookingTime,
      property_type: PropertyType,
      pets: HavePets,
      additional_preference: AdditionalPreference,
    };
  } else if (ServiceName === "Deep Cleaning") {
    payload = {
      name: customerName,
      phone: Phone,
      email: customerEmail,
      address: customerAddress,
      service_name: ServiceName,
      postcode: Postcode,
      date: BookingDate,
      time: BookingTime,
      propertyType: PropertyType,
      propertySize: PropertySize,
      mattressSteamCleaningSingle: MattressSteamCleaningSingle,
      mattressSteamCleaningQueen: MattressSteamCleaningQueen,
      mattressSteamCleaningKing: MattressSteamCleaningKing,
      ovenCleaning: OvenCleaning,
      degreasingKitchen: DegreasingKitchen,
      steamCleanKitchenFloor: SteamCleanKitchenFloor,
      fridgeCleaning: FridgeCleaning,
      removalofTapeStains: RemovalofTapeStains,
      pets: HavePets,
      additional_preference: AdditionalPreference,
    };
  } else if (ServiceName === "Moving") {
    payload = {
      name: customerName,
      phone: Phone,
      email: customerEmail,
      service_name: ServiceName,
      postcode: Postcode,
      date: BookingDate,
      time: BookingTime,
      movingType: MovingType,
      pickupAddress: PickupAddress,
      pickupPropertyType: PickupPropertyType,
      dropoffAddress: DropoffAddress,
      dropoffPropertyType: DropoffPropertyType,
      listofItems: ListofItems,
      inspectionDate: InspectionDate,
      inspectionTime: InspectionTime,
    };
  } else if (ServiceName === "Handyman") {
    payload = {
      name: customerName,
      phone: Phone,
      email: customerEmail,
      service_name: ServiceName,
      postcode: Postcode,
      date: BookingDate,
      time: BookingTime,
      selectedServices: SelectedServices,
      pets: HavePets,
      additional_preference: AdditionalPreference,
    };
  } else {
    payload = {
      name: customerName,
      phone: Phone,
      email: customerEmail,
      service_name: ServiceName,
      postcode: Postcode,
      date: BookingDate,
      time: BookingTime,
      service_details: ServiceDetails,
      pets: HavePets,
      additional_preference: AdditionalPreference,
    };
  }

  return (
    <div className="w-full h-full">
      <Header />
      <div>
        <div className="w-full h-full flex booking-req-container">
          <div className="bg-white h-full booking-req-white-box">
            <div className="flex booking-req-header text-sm ml-2 mr-1">
              <div className="flex mt-10">
                <CustomText
                  label="Cleaning Requirements"
                  labelStyle="font-semibold text-blck"
                  underlineStyle="border-t border-black border-solid border-2 my-2 w-44 ml-0"
                />
              </div>
              <div className="flex booking-req-inactive">
                <CustomText
                  label="Booking Confirmation"
                  labelStyle="font-semibold  text-black"
                  underlineStyle="border-t border-black border-solid border-2 my-2 w-44 ml-0"
                />
              </div>
              <div className="flex booking-cnf-active">
                <CustomText
                  label="Your Details"
                  labelStyle="font-semibold text-teal-500"
                  underlineStyle="border-t border-teal-500 border-solid border-2 my-2 w-30 ml-0"
                />
              </div>
            </div>
            <div className="booking-req-sub-header mt-20 font-semibold">
              <CustomText
                label="Enter your details to confirm booking "
                labelStyle="font-bold text-black"
              />
            </div>
            <div className=" h-[400px] ml-10 mr-20 bg-white booking-input-details">
              <div className="">
                <TextField
                  styles="w-full"
                  placeHolder="Name"
                  id="name"
                  name="name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
                <PhoneInput
                  className="mt-[20px]"
                  id="phone"
                  name="phone"
                  style={{ borderRadius: "7px" }}
                  inputStyle={{
                    width: "100%",
                    height: "43px",
                    borderRadius: "7px",
                  }}
                  country={"sg"}
                  onChange={handleOnChangePhone}
                />
                <TextField
                  styles="w-full"
                  placeHolder="Email"
                  id="email"
                  name="email"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <TextField
                  styles="w-full"
                  placeHolder="Address"
                  id="address"
                  name="address"
                  value={customerAddress}
                  onChange={(e) => setCustomerAddress(e.target.value)}
                />
                <Button
                  label="Submit"
                  id="submit"
                  name="submit"
                  styles="mt-[10px] w-full text-white h-[40px] justify-center px-6 bg-teal-500 hover:bg-teal-400 hover:scale-110"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className="bg-slate-200 h-full booking-req-gray-box">
            <RenderIf condition={ServiceName === "Professional Cleaning"}>
              <ProfessionalCleaningDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Deep Cleaning"}>
              <DeepCleaningDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Handyman"}>
              <HandymanDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Moving"}>
              <MovingDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Painting"}>
              <PaintingDetails />
            </RenderIf>
            <RenderIf
              condition={ServiceName === "Outdoor Decking Repair & Polishing"}
            >
              <OutdoorDeckingDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Plumbing"}>
              <PlumbingDetails />
            </RenderIf>
            <RenderIf
              condition={ServiceName === "Marble Repairing & Polishing"}
            >
              <MarbleRepairDetails />
            </RenderIf>
            <RenderIf
              condition={ServiceName === "Timber / Parquet Repair & Polishing"}
            >
              <TimberParquetDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Electrician"}>
              <ElectricianDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Carpentry"}>
              <CarpentryDetails />
            </RenderIf>
            <RenderIf condition={ServiceName === "Floor Repairing & Polishing"}>
              <FloorRepairDetails />
            </RenderIf>
          </div>
        </div>
      </div>
    </div>
  );
}
