import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Ironing: "",
  AdditionalPreference: "",
};

export const addOnsSlice = createSlice({
  name: "addOns",
  initialState,
  reducers: {
    setIroning(state, action) {
      state.Ironing = action.payload;
    },
    setAdditionalPreference(state, action) {
      state.AdditionalPreference = action.payload;
    },
  },
});

export const { setIroning, setAdditionalPreference } = addOnsSlice.actions;

export default addOnsSlice.reducer;
