import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Name: "",
  Phone: "",
  Email: "",
  Message: "",
};

export const contactusSlice = createSlice({
  name: "contactus",
  initialState,
  reducers: {
    setName(state, action) {
      state.Name = action.payload;
    },
    setPhone(state, action) {
      state.Phone = action.payload;
    },
    setEmail(state, action) {
      state.Email = action.payload;
    },
    setMessage(state, action) {
      state.Message = action.payload;
    },
  },
});

// Export action creators
export const { setName, setPhone, setEmail, setMessage } =
  contactusSlice.actions;

// Export the reducer
export default contactusSlice.reducer;
