import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/sharedComponents/Header";
import { CustomText } from "../components/sharedComponents/CustomText/CustomText";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCleaningFrequency,
  setHoursperSession,
} from "../constants/Redux/Slice/cleaningSlice";
import { setTotalPrice } from "../constants/Redux/Slice/pricingSlice";
import RenderIf from "../components/sharedComponents/RenderIf";
import { ProfessionalCleaning } from "../components/pageComponents/BookingRequirement/ProfessionalCleaning";
import { ProfessionalCleaningDetails } from "../components/pageComponents/BookingRequirement/ProfessionalCleaningDetails";
import { DeepCleaning } from "../components/pageComponents/BookingRequirement/DeepCleaning";
import { DeepCleaningDetails } from "../components/pageComponents/BookingRequirement/DeepCleaningDetails";
import { Handyman } from "../components/pageComponents/BookingRequirement/Handyman";
import { Moving } from "../components/pageComponents/BookingRequirement/Moving";
import { MovingDetails } from "../components/pageComponents/BookingRequirement/MovingDetails";
import { HandymanDetails } from "../components/pageComponents/BookingRequirement/HandymanDetails";
import { Painting } from "../components/pageComponents/BookingRequirement/Painting";
import { PaintingDetails } from "../components/pageComponents/BookingRequirement/PaintingDetails";
import { Electrician } from "../components/pageComponents/BookingRequirement/Electrician";
import { ElectricianDetails } from "../components/pageComponents/BookingRequirement/ElectricianDetails";
import { Plumbing } from "../components/pageComponents/BookingRequirement/Plumbing";
import { PlumbingDetails } from "../components/pageComponents/BookingRequirement/PlumbingDetails";
import { FloorRepair } from "../components/pageComponents/BookingRequirement/FloorRepair";
import { FloorRepairDetails } from "../components/pageComponents/BookingRequirement/FloorRepairDetails";
import { MarbleRepair } from "../components/pageComponents/BookingRequirement/MarbleRepair";
import { MarbleRepairDetails } from "../components/pageComponents/BookingRequirement/MarbleRepairDetails";
import { OutdoorDecking } from "../components/pageComponents/BookingRequirement/OutdoorDecking";
import { OutdoorDeckingDetails } from "../components/pageComponents/BookingRequirement/OutdoorDeckingDetails";
import { Carpentry } from "../components/pageComponents/BookingRequirement/Carpentry";
import { CarpentryDetails } from "../components/pageComponents/BookingRequirement/CarpentryDetails";
import { TimberParquet } from "../components/pageComponents/BookingRequirement/TimberParquet";
import { TimberParquetDetails } from "../components/pageComponents/BookingRequirement/TimberParquetDetails";

const BookingRequirement = () => {
  let content;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ServiceName, Postcode } = useSelector((state) => state.searchbar);
  console.log("service name = ", ServiceName);
  console.log("post code = ", Postcode);
  const {
    CleaningFrequency,
    StartingDate,
    BookingTime,
    PropertyType,
    HoursperSession,
  } = useSelector((state) => state.cleaning);
  const { TotalPrice } = useSelector((state) => state.pricing);
  console.log("cleaning frequency = ", CleaningFrequency);
  console.log("hours per session = ", HoursperSession);
  console.log("Starting date = ", StartingDate);

  return (
    <div className="w-full h-full">
      <Header />
      <div className="w-full h-full flex booking-req-container">
        <div className="bg-white h-full booking-req-white-box">
          <div className="flex booking-req-header text-sm ml-2 mr-1">
            <div className="flex mt-10">
              <CustomText
                label="Booking Requirements"
                labelStyle="  text-teal-500 font-bold"
                underlineStyle="border-t border-teal-500 border-solid border-2 my-2 w-44 ml-0"
              />
            </div>
            <div className="flex booking-req-inactive">
              <CustomText
                label="Booking Confirmation"
                labelStyle=" text-slate-400"
                underlineStyle="border-t border-slate-400 border-solid border-2 my-2 w-44 ml-0"
              />
            </div>
            <div className="flex booking-req-inactive">
              <CustomText
                label="Your Details"
                labelStyle=" text-slate-400"
                underlineStyle="border-t border-slate-400 border-solid border-2 my-2 w-44 ml-0"
              />
            </div>
          </div>
          <RenderIf condition={ServiceName === "Professional Cleaning"}>
            <ProfessionalCleaning />
          </RenderIf>
          <RenderIf condition={ServiceName === "Deep Cleaning"}>
            <DeepCleaning />
          </RenderIf>
          <RenderIf condition={ServiceName === "Handyman"}>
            <Handyman />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Outdoor Decking Repair & Polishing"}
          >
            <OutdoorDecking />
          </RenderIf>
          <RenderIf condition={ServiceName === "Painting"}>
            <Painting />
          </RenderIf>
          <RenderIf condition={ServiceName === "Plumbing"}>
            <Plumbing />
          </RenderIf>
          <RenderIf condition={ServiceName === "Moving"}>
            <Moving />
          </RenderIf>
          <RenderIf condition={ServiceName === "Marble Repairing & Polishing"}>
            <MarbleRepair />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Timber / Parquet Repair & Polishing"}
          >
            <TimberParquet />
          </RenderIf>
          <RenderIf condition={ServiceName === "Electrician"}>
            <Electrician />
          </RenderIf>
          <RenderIf condition={ServiceName === "Carpentry"}>
            <Carpentry />
          </RenderIf>
          <RenderIf condition={ServiceName === "Floor Repairing & Polishing"}>
            <FloorRepair />
          </RenderIf>
        </div>
        <div className="bg-slate-200 h-full booking-req-gray-box">
          <RenderIf condition={ServiceName === "Professional Cleaning"}>
            <ProfessionalCleaningDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Deep Cleaning"}>
            <DeepCleaningDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Handyman"}>
            <HandymanDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Moving"}>
            <MovingDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Painting"}>
            <PaintingDetails />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Outdoor Decking Repair & Polishing"}
          >
            <OutdoorDeckingDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Plumbing"}>
            <PlumbingDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Marble Repairing & Polishing"}>
            <MarbleRepairDetails />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Timber / Parquet Repair & Polishing"}
          >
            <TimberParquetDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Electrician"}>
            <ElectricianDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Carpentry"}>
            <CarpentryDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Floor Repairing & Polishing"}>
            <FloorRepairDetails />
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default BookingRequirement;
