import React from "react";
import Typography from "@mui/material/Typography";

function DescriptionBox({
  label,
  label2,
  bgImage,
  labelStyle,
  label2Style,
  boxStyle,
}) {
  return (
    <div className={`${boxStyle}`}>
      {/* <img src={bgImage} alt="background" className="w-full h-[200px]" /> */}
      <div className={`${labelStyle}`}>{label}</div>
      <div className={`${label2Style}`}>{label2}</div>
    </div>
  );
}

export default DescriptionBox;
