import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ServiceDetails: "",
};

export const serviceDetailsSlice = createSlice({
  name: "serviceDetails",
  initialState,
  reducers: {
    setServiceDetails(state, action) {
      state.ServiceDetails = action.payload;
    },
  },
});

// Export action creators
export const { setServiceDetails } = serviceDetailsSlice.actions;

// Export the reducer
export default serviceDetailsSlice.reducer;
