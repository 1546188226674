import React from "react";

function ImageBox({ bgImage, styles }) {
  return (
    <div className={`${styles}`}>
      <img
        src={bgImage}
        alt="background"
        className="w-full h-full rounded-lg"
      />
    </div>
  );
}

export default ImageBox;
