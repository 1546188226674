import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import DateTimeBox from "../Booking/DateTimeBox";
import { setListofItems } from "../../../constants/Redux/Slice/movingSlice";

export const Moving = () => {
  const [listItems, setListItems] = useState("");
  const disptach = useDispatch();
  disptach(setListofItems(listItems));
  return (
    <div>
      <div>
        <CustomText
          label="Moving information"
          labelStyle="font-medium booking-req-sub-header mt-10"
          styles="mt-10"
        />
      </div>
      <div>
        <CustomText
          label="List down all the items you would like to move"
          labelStyle="font-normal booking-req-sub-header2 ml-20 "
          styles="mt-2"
        />
      </div>
      <div>
        <CustomText
          label="Your accurate descriptions help us protect your items during the move"
          labelStyle="font-normal booking-req-sub-header2 ml-20 "
          styles="mt-2"
        />
      </div>
      <input
        type="text"
        className="ml-20 mt-8  h-[120px] outline outline-2 w-[250px] outline-slate-300 rounded-[10px] justify-start booking-req-dropdown"
        placeholder="List of items ..."
        style={{ textIndent: "20px", paddingBottom: "40px" }}
        value={listItems}
        onChange={(e) => setListItems(e.target.value)}
      />
      <div>
        <CustomText
          label="Inspection Date & Time"
          labelStyle="font-medium booking-req-sub-header mt-10"
          styles="mt-10"
        />
      </div>
      <div>
        <CustomText
          label="Our service partner will inspect your pick up location & inventory items to provide a quotation"
          labelStyle="font-normal booking-req-sub-header2 ml-20 "
          styles="mt-2"
        />
      </div>
      <div>
        <DateTimeBox
          label="Select date & time"
          boxStyle=" mt-2 ml-1 mr-1 booking-req-dropdown"
          type="inspection"
        />
      </div>
    </div>
  );
};
