import React from "react";

const Button = ({ styles, label, onClick }) => (
  <button
    type="button"
    className={` font-poppins font-medium ${styles}`}
    onClick={onClick}
  >
    {label}
  </button>
);

export default Button;
