import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import Button from "../../sharedComponents/Button/Button";
import {
  setMattressSteamCleaningSingle,
  setMattressSteamCleaningQueen,
  setMattressSteamCleaningKing,
  setOvenCleaning,
  setDegreasingKitchen,
  setSteamCleanKitchenFloor,
  setFridgeCleaning,
  setRemovalofTapeStains,
} from "../../../constants/Redux/Slice/deepCleaningSlice";

export const Item = ({ id, name, count }) => {
  const [itemCount, setItemCount] = useState(count);
  const dispatch = useDispatch();
  const handleIncrement = () => {
    const updatedCount = itemCount + 1;
    setItemCount(updatedCount);
    console.log("name :", name);
    switch (name) {
      case "Mattress Steam Cleaning Single":
        dispatch(setMattressSteamCleaningSingle(updatedCount));
        break;
      case "Mattress Steam Cleaning Queen":
        dispatch(setMattressSteamCleaningQueen(updatedCount));
        break;
      case "Mattress Steam Cleaning King":
        dispatch(setMattressSteamCleaningKing(updatedCount));
        break;
      case "Oven Cleaning":
        dispatch(setOvenCleaning(updatedCount));
        break;
      case "Degreasing Kitchen (incl. hood)":
        dispatch(setDegreasingKitchen(updatedCount));
        break;
      case "Steam Clean Kitchen Floor":
        dispatch(setSteamCleanKitchenFloor(updatedCount));
        break;
      case "Fridge Cleaning":
        dispatch(setFridgeCleaning(updatedCount));
        break;
      case "Removal of Tape Stains":
        dispatch(setRemovalofTapeStains(updatedCount));
        break;
      default:
        console.log("default case", name);
    }
  };

  const handleDecrement = () => {
    const updatedCount = itemCount > 0 ? itemCount - 1 : 0;
    setItemCount(updatedCount);
    switch (name) {
      case "Mattress Steam Cleaning Single":
        dispatch(setMattressSteamCleaningSingle(updatedCount));
        break;
      case "Mattress Steam Cleaning Queen":
        dispatch(setMattressSteamCleaningQueen(updatedCount));
        break;
      case "Mattress Steam Cleaning King":
        dispatch(setMattressSteamCleaningKing(updatedCount));
        break;
      case "Oven Cleaning":
        dispatch(setOvenCleaning(updatedCount));
        break;
      case "Degreasing Kitchen (incl. hood)":
        dispatch(setDegreasingKitchen(updatedCount));
        break;
      case "Steam Clean Kitchen Floor":
        dispatch(setSteamCleanKitchenFloor(updatedCount));
        break;
      case "Fridge Cleaning":
        dispatch(setFridgeCleaning(updatedCount));
        break;
      case "Removal of Tape Stains":
        dispatch(setRemovalofTapeStains(updatedCount));
        break;
      default:
        console.log("No options", name);
    }
  };
  // console.log("item count: ", itemCount);
  // console.log("item name :", name);
  return (
    <div className="flex flex-row w-full ">
      <div className="w-[220px] ">
        <CustomText
          label={name}
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div className="flex flex-row ml-5 ">
        <div>
          <Button
            label="-"
            onClick={handleDecrement}
            styles=" text-white  font-semibold h-6 mt-2 bg-teal-500 outline outline-1 rounded-[5px] outline-black hover:text-black hover:bg-teal-300 pl-2 pr-2"
          />
        </div>
        <div className="text-center">
          <CustomText
            label={itemCount}
            labelStyle="font-normal  mt-5"
            styles="font-normal  mt-2 ml-1 mr-1"
          />
        </div>
        <div>
          <Button
            label="+"
            onClick={handleIncrement}
            styles=" text-white  font-semibold h-6 mt-2 bg-teal-500 outline outline-1 rounded-[5px] outline-black hover:text-black hover:bg-teal-300 pl-2 pr-2"
          />
        </div>
      </div>
    </div>
  );
};
