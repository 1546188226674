import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  MovingType: "",
  PickupAddress: "",
  PickupPropertyType: "",
  DropoffAddress: "",
  DropoffPropertyType: "",
  ListofItems: "",
  InspectionDate: "",
  InspectionTime: "",
};

export const movingSlice = createSlice({
  name: "moving",
  initialState,
  reducers: {
    setMovingType(state, action) {
      state.MovingType = action.payload;
    },
    setPickupAddress(state, action) {
      state.PickupAddress = action.payload;
    },
    setPickupPropertyType(state, action) {
      state.PickupPropertyType = action.payload;
    },
    setDropoffAddress(state, action) {
      state.DropoffAddress = action.payload;
    },
    setDropoffPropertyType(state, action) {
      state.DropoffPropertyType = action.payload;
    },
    setListofItems(state, action) {
      state.ListofItems = action.payload;
    },
    setInspectionDate(state, action) {
      state.InspectionDate = action.payload;
    },
    setInspectionTime(state, action) {
      state.InspectionTime = action.payload;
    },
  },
});

export const {
  setMovingType,
  setPickupAddress,
  setPickupPropertyType,
  setDropoffAddress,
  setDropoffPropertyType,
  setListofItems,
  setInspectionDate,
  setInspectionTime,
} = movingSlice.actions;

export default movingSlice.reducer;
