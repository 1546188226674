import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import { CustomDropdown } from "../Booking/CustomDropdwon";
import { setAdditionalPreference } from "../../../constants/Redux/Slice/addOnsSlice";

export const FloorRepair = () => {
  const dispatch = useDispatch();
  const [addPreference, setAddPreference] = useState("");
  dispatch(setAdditionalPreference(addPreference));
  return (
    <div>
      <div>
        <CustomText
          label="Additional information (optional)"
          labelStyle="font-medium booking-req-sub-header mt-20"
          styles="mt-10"
        />
      </div>
      <div>
        <CustomText
          label="Do you have any pets at home ?"
          labelStyle="font-normal booking-req-sub-header2 ml-20 "
          styles="mt-2"
        />
      </div>
      <div>
        <CustomDropdown
          label="No i don't have"
          boxStyle="h-12 ml-20 mt-5 booking-req-dropdown"
          buttonStyle="h-12"
          optionType="Have Pets"
        />
      </div>
      <input
        type="text"
        className="ml-20 mt-8  h-[120px] outline outline-2 w-[250px] outline-slate-300 rounded-[10px] justify-start booking-req-dropdown"
        placeholder="Any additional preferences ?"
        style={{ textIndent: "20px", paddingBottom: "40px" }}
        value={addPreference}
        onChange={(e) => setAddPreference(e.target.value)}
      />
    </div>
  );
};
