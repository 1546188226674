import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  SelectedServices: "",
};

export const handymanSlice = createSlice({
  name: "handyman",
  initialState,
  reducers: {
    setSelectedServices(state, action) {
      state.SelectedServices = action.payload;
    },
  },
});

export const { setSelectedServices } = handymanSlice.actions;

export default handymanSlice.reducer;
