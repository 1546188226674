import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  StartingDate: "",
  BookingTime: "",
  PropertyType: "",
  PropertySize: "",
  MattressSteamCleaningSingle: "",
  MattressSteamCleaningQueen: "",
  MattressSteamCleaningKing: "",
  OvenCleaning: "",
  DegreasingKitchen: "",
  SteamCleanKitchenFloor: "",
  FridgeCleaning: "",
  RemovalofTapeStains: "",
};

export const deepCleaningSlice = createSlice({
  name: "deepCleaning",
  initialState,
  reducers: {
    setStartingDate(state, action) {
      state.StartingDate = action.payload;
    },
    setBookingTime(state, action) {
      state.BookingTime = action.payload;
    },
    setPropertyType(state, action) {
      state.PropertyType = action.payload;
    },
    setPropertySize(state, action) {
      state.PropertySize = action.payload;
    },
    setMattressSteamCleaningSingle(state, action) {
      state.MattressSteamCleaningSingle = action.payload;
    },
    setMattressSteamCleaningQueen(state, action) {
      state.MattressSteamCleaningQueen = action.payload;
    },
    setMattressSteamCleaningKing(state, action) {
      state.MattressSteamCleaningKing = action.payload;
    },
    setOvenCleaning(state, action) {
      state.OvenCleaning = action.payload;
    },
    setDegreasingKitchen(state, action) {
      state.DegreasingKitchen = action.payload;
    },
    setSteamCleanKitchenFloor(state, action) {
      state.SteamCleanKitchenFloor = action.payload;
    },
    setFridgeCleaning(state, action) {
      state.FridgeCleaning = action.payload;
    },
    setRemovalofTapeStains(state, action) {
      state.RemovalofTapeStains = action.payload;
    },
  },
});

export const {
  setStartingDate,
  setBookingTime,
  setPropertyType,
  setPropertySize,
  setMattressSteamCleaningSingle,
  setMattressSteamCleaningQueen,
  setMattressSteamCleaningKing,
  setOvenCleaning,
  setDegreasingKitchen,
  setSteamCleanKitchenFloor,
  setFridgeCleaning,
  setRemovalofTapeStains,
} = deepCleaningSlice.actions;

export default deepCleaningSlice.reducer;
