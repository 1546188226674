import React from "react";
import { DropdownBox } from "../Booking/DropdownBox";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import DateTimeBox from "../Booking/DateTimeBox";
import { CustomDropdown } from "../Booking/CustomDropdwon";
import { ItemList } from "./ItemList";

export const DeepCleaning = () => {
  const Items = [
    { id: 1, name: "Mattress Steam Cleaning Single", count: 0 },
    { id: 2, name: "Mattress Steam Cleaning Queen", count: 0 },
    { id: 3, name: "Mattress Steam Cleaning King", count: 0 },
    { id: 4, name: "Oven Cleaning", count: 0 },
    { id: 5, name: "Degreasing Kitchen (incl. hood)", count: 0 },
    { id: 6, name: "Steam Clean Kitchen Floor", count: 0 },
    { id: 7, name: "Fridge Cleaning", count: 0 },
    { id: 8, name: "Removal of Tape Stains", count: 0 },
  ];
  return (
    <div>
      <div>
        <CustomText
          label="Starting date & time"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-20 ml-1 mr-1"
        />
      </div>

      <div>
        <DateTimeBox
          label="Select date & time"
          boxStyle=" mt-2 ml-1 mr-1 booking-req-dropdown"
        />
      </div>

      <div>
        <CustomText
          label="About your property"
          labelStyle="font-medium  mt-10 booking-req-sub-header"
          styles="font-medium  mt-10 ml-1 mr-1"
        />
      </div>
      <div>
        <CustomText
          label="Type of property"
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div>
        <DropdownBox
          label="Select property type"
          boxStyle=" h-12 mt-2 ml-1 mr-1 booking-req-dropdown"
          buttonStyle=" h-12"
          selectedService="Deep Cleaning"
          type="normal"
        />
      </div>
      <div>
        <CustomText
          label="Size of property"
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div>
        <CustomDropdown
          label="Select size"
          boxStyle="h-12 ml-20 mt-2 booking-req-dropdown"
          buttonStyle="h-12"
          optionType="Property size"
        />
      </div>
      <div>
        <CustomText
          label="Add-on services (optional)"
          labelStyle="font-medium  mt-10 booking-req-sub-header"
          styles="font-medium  mt-10 ml-1 mr-1"
        />
      </div>
      <div>
        <CustomText
          label="We also offer the following services as add-ons:"
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div>
        <ItemList items={Items} />
      </div>
    </div>
  );
};
