import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function ServiceTabItem({
  id,
  label,
  icon,
  bg,
  customStyles,
  textColor = "text-white",
  variant = "outlined",
  loading = false,
}) {
  return (
    <div>
      <Button
        id={id}
        variant={variant}
        className={`${bg}   ${customStyles} ${
          textColor ? textColor : "text-white"
        } w-full capitalize font-[550] rounded-lg outline-none shadow-none`}
      >
        <Typography className={`${icon && "ml-3"}`} variant="body2">
          {/* <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
      Processing...
    </svg> */}

          {loading ? "Loading ..." : label}
        </Typography>
      </Button>
    </div>
  );
}

export default ServiceTabItem;
