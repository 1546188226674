import { SanitizerOutlined } from "@mui/icons-material";
import React from "react";
import Stack from "@mui/material/Stack";
import icon1 from "../../../assets/images/icon1.png";
import icon2 from "../../../assets/images/icon2.png";
import icon3 from "../../../assets/images/icon3.png";
import icon4 from "../../../assets/images/icon4.png";
import icon5 from "../../../assets/images/icon5.png";
import icon6 from "../../../assets/images/icon6.png";
import icon7 from "../../../assets/images/icon7.png";
import icon8 from "../../../assets/images/icon8.png";
import icon9 from "../../../assets/images/icon9.png";
import icon10 from "../../../assets/images/icon10.png";
import icon11 from "../../../assets/images/icon11.png";
import icon12 from "../../../assets/images/icon12.png";
import icon13 from "../../../assets/images/icon13.png";
import ServiceOptions from "./ServiceOptions";

function Servicesbox() {
  return (
    <div className="w-full h-full pb-10">
      <div className="service-box z-0">
        <div className="service-stack z-0">
          <div className="flex flex-row z-0 mt-2">
            <ServiceOptions
              label="Professional Cleaning"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label "
              imageUrl={icon1}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Deep Cleaning"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon12}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Painting"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon3}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Plumbing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon7}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
          </div>
          <Stack className="mt-2" direction="row">
            <ServiceOptions
              label="Electrician"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon13}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Carpentry"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon6}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Handyman"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon7}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Moving"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon8}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
          </Stack>
          <Stack className="mt-2" direction="row">
            <ServiceOptions
              label="Floor Repairing & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon2}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Outdoor decking Repair & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon8}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Timber / Parquet Repair & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon11}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Marble Repairing & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={icon2}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              contentStyle="service-service-options-content"
            />
          </Stack>
        </div>
        {/* <div className="service-stack">
        <div className="service-item">
          <div className="service-icon">
            <img src={icon1} alt="icon" />
          </div>
          <div className="service-title">Professional Cleaning</div>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <img src={icon1} alt="icon" />
          </div>
          <div className="service-title">Deep Cleaning</div>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <img src={icon6} alt="icon" />
          </div>
          <div className="service-title">Carpentry</div>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <img src={icon3} alt="icon" />
          </div>
          <div className="service-title">Painting</div>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <img src={icon4} alt="icon" />
          </div>
          <div className="service-title">Plumbing</div>
        </div>
        <div className="service-item">
          <div className="service-icon">
            <img src={icon5} alt="icon" />
          </div>
          <div className="service-title">Electrician</div>
        </div>
      </div> */}
        <div className="service-stack"></div>
      </div>
    </div>
  );
}

export default Servicesbox;
