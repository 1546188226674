import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Name: "",
  Phone: "",
  Email: "",
  Address: "",
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setName(state, action) {
      state.Name = action.payload;
    },
    setPhone(state, action) {
      state.Phone = action.payload;
    },
    setEmail(state, action) {
      state.Email = action.payload;
    },
    setAddress(state, action) {
      state.Address = action.payload;
    },
  },
});

// Export action creators
export const { setName, setPhone, setEmail, setAddress } =
  customerSlice.actions;

// Export the reducer
export default customerSlice.reducer;
