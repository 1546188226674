import React from "react";
import DescriptionBox from "./DescriptionBox";
import ImageBox from "./ImageBox";
import bgimage from "../../../assets/images/apac 1.png";
import img01 from "../../../assets/images/img01.jpg";
import img02 from "../../../assets/images/img02.jpg";
import img03 from "../../../assets/images/img03.jpg";
import img04 from "../../../assets/images/img04.jpg";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";

export const WhyApac = () => {
  return (
    <div className="w-full flex flex-col h-full pb-10">
      <div className=" justify-center whyapac-header">
        <CustomText
          label="Why  "
          label2="Apac Engineers"
          labelStyle="text-gray-700 "
          labelStyle2="text-teal-500 "
          styles="font-extrabold uppercase why-apac-header"
          underlineStyle="border-t border-teal-500 border-solid why-apac-header-underline"
        />
      </div>
      <div className="flex space-x-3 justify-center whyapac-flex-container">
        <div className="justify-center flex whyapac-image basis-1/4  mt-4 ">
          <ImageBox bgImage={img01} styles="why-apac-image-box" />
          <ImageBox bgImage={img02} styles="why-apac-image-box" />
          <ImageBox bgImage={img03} styles="why-apac-image-box" />
          <ImageBox bgImage={img04} styles="why-apac-image-box" />
        </div>
        <div className="justify-center flex whyapac-text basis-1/4 mt-4 text-center">
          <DescriptionBox
            label="Best Quality Home Services"
            labelStyle=" font-medium uppercase why-apac-descriptionbox-text"
            boxStyle="why-apac-descriptionbox"
          />
          <DescriptionBox
            label="Fully Equipped"
            labelStyle="font-medium uppercase why-apac-descriptionbox-text"
            boxStyle="why-apac-descriptionbox"
          />
          <DescriptionBox
            label="Hand-Picked, Professional Crew"
            labelStyle=" font-medium uppercase why-apac-descriptionbox-text"
            boxStyle="why-apac-descriptionbox"
          />
          <DescriptionBox
            label="Always on-time"
            labelStyle=" font-medium uppercase why-apac-descriptionbox-text"
            boxStyle="why-apac-descriptionbox"
          />
        </div>
      </div>
    </div>
  );
};
