import { configureStore } from "@reduxjs/toolkit";
import searchbarReducer from "./Slice/searchbarSlice";
import dateTimeReducer from "./Slice/dateTimeSlice";
import propertyTypeReducer from "./Slice/propertyTypeSlice";
import cleaningReducer from "./Slice/cleaningSlice";
import customerReducer from "./Slice/customerSlice";
import petsReducer from "./Slice/petsSlice";
import addOnsReducer from "./Slice/addOnsSlice";
import pricingReducer from "./Slice/pricingSlice";
import deepCleaningReducer from "./Slice/deepCleaningSlice";
import handymanReducer from "./Slice/handymanSlice";
import movingReducer from "./Slice/movingSlice";
import serviceDetailsReducer from "./Slice/serviceDetailsSlice";
import contactusReducer from "./Slice/contactusSlice";

export const store = configureStore({
  reducer: {
    searchbar: searchbarReducer,
    dateTime: dateTimeReducer,
    propertyType: propertyTypeReducer,
    cleaning: cleaningReducer,
    customer: customerReducer,
    pets: petsReducer,
    addOns: addOnsReducer,
    serviceDetails: serviceDetailsReducer,
    pricing: pricingReducer,
    contactus: contactusReducer,
    deepCleaning: deepCleaningReducer,
    handyman: handymanReducer,
    moving: movingReducer,
  },
});
