import React from "react";
import Topnavbar from "../components/sharedComponents/Topnavbar";
import { Herosection } from "../components/pageComponents/Home/Herosection";
import bgimage from "../../src/assets/images/header_background.jpg";
import heroimage from "../assets/images/hero.png";
import { Searchbar } from "../components/pageComponents/Home/Searchbar";
import { ServicesTab } from "../components/pageComponents/Home/ServicesTab";
import { WhyApac } from "../components/pageComponents/Home/WhyApac";
import { Aboutus } from "../components/pageComponents/Home/Aboutus";
import DescriptionBox from "../components/pageComponents/Home/DescriptionBox";
import { WhatsappButton } from "../components/sharedComponents/Button/WhatsappButton";
import Services from "../components/sharedComponents/Services";
import Footer from "../components/sharedComponents/Footer";
import DropdownExample from "../components/pageComponents/Home/Dropdown";
import Header from "../components/sharedComponents/Header";
import Servicesbox from "../components/pageComponents/Home/Servicesbox";
import Contactus from "../components/sharedComponents/Contactus";

export default function Home() {
  return (
    <div>
      <Header />
      {/* <Phone/> */}
      {/* <Topnavbar /> */}
      <Herosection
        bgImage={bgimage}
        heroImage={heroimage}
        customComponent={
          <DescriptionBox
            label={"APAC ENGINEERS -"}
            label2={"Best Quality Home Services on-demand! "}
            boxStyle="hero-tagline-box"
            labelStyle="hero-tagline1"
            label2Style="hero-tagline2"
          />
        }
        customComponent2={<Searchbar />}
        styles="hero-image"
      ></Herosection>

      {/* <WhatsappButton /> */}
      {/* <ServicesTab /> */}
      <Servicesbox />

      <WhyApac />
      <Aboutus />
      <Services />
      <Contactus />

      {/* <Footer /> */}
    </div>
  );
}
