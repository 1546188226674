import React from "react";

export const Herosection = ({
  bgImage,
  heroImage,
  styles,
  customComponent,
  customComponent2,
  children,
}) => {
  return (
    <div className="w-full relative">
      {/* Background Image */}
      <div
        className="hero-background"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        {/* Overlay Image */}
        <div className="hero-components">
          <div>
            {customComponent && (
              <div className="absolute ">{customComponent}</div>
            )}
            {customComponent2 && (
              <div className="absolute ">{customComponent2}</div>
            )}
          </div>
          <div className="">
            <img src={heroImage} alt="Overlay Image" className={`${styles}`} />
          </div>
        </div>
      </div>
    </div>
  );
};
