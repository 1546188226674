import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/sharedComponents/Header";
import { CustomText } from "../components/sharedComponents/CustomText/CustomText";
import Button from "../components/sharedComponents/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DropdownBox } from "../components/pageComponents/Booking/DropdownBox";
import { CustomDropdown } from "../components/pageComponents/Booking/CustomDropdwon";
import {
  setIroning,
  setAdditionalPreference,
} from "../constants/Redux/Slice/addOnsSlice";
import { ProfessionalCleaning } from "../components/pageComponents/BookingConfirmation/ProfessionalCleaning";
import RenderIf from "../components/sharedComponents/RenderIf";
import { ProfessionalCleaningDetails } from "../components/pageComponents/BookingConfirmation/ProfessionalCleaningDetails";
import { DeepCleaning } from "../components/pageComponents/BookingConfirmation/DeepCleaning";
import { DeepCleaningDetails } from "../components/pageComponents/BookingConfirmation/DeepCleaningDetails";
import { Moving } from "../components/pageComponents/BookingConfirmation/Moving";
import { MovingDetails } from "../components/pageComponents/BookingConfirmation/MovingDetails";
import { HandymanDetails } from "../components/pageComponents/BookingConfirmation/HandymanDetails";
import { Handyman } from "../components/pageComponents/BookingConfirmation/Handyman";
import { Painting } from "../components/pageComponents/BookingConfirmation/Painting";
import { PaintingDetails } from "../components/pageComponents/BookingConfirmation/PaintingDetails";
import { Plumbing } from "../components/pageComponents/BookingConfirmation/Plumbing";
import { PlumbingDetails } from "../components/pageComponents/BookingConfirmation/PlumbingDetails";
import { Carpentry } from "../components/pageComponents/BookingConfirmation/Carpentry";
import { CarpentryDetails } from "../components/pageComponents/BookingConfirmation/CarpentryDetails";
import { Electrician } from "../components/pageComponents/BookingConfirmation/Electrician";
import { ElectricianDetails } from "../components/pageComponents/BookingConfirmation/ElectricianDetails";
import { FloorRepair } from "../components/pageComponents/BookingConfirmation/FloorRepair";
import { FloorRepairDetails } from "../components/pageComponents/BookingConfirmation/FloorRepairDetails";
import { MarbleRepair } from "../components/pageComponents/BookingConfirmation/MarbleRepair";
import { MarbleRepairDetails } from "../components/pageComponents/BookingConfirmation/MarbleRepairDetails";
import { OutdoorDecking } from "../components/pageComponents/BookingConfirmation/OutdoorDecking";
import { OutdoorDeckingDetails } from "../components/pageComponents/BookingConfirmation/OutdoorDeckingDetails";
import { TimberParquet } from "../components/pageComponents/BookingConfirmation/TimberParquet";
import { TimberParquetDetails } from "../components/pageComponents/BookingConfirmation/TimberParquetDetails";

const BookingConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
    dispatch(setIroning(!isChecked));
  };
  const { TotalPrice } = useSelector((state) => state.pricing);
  const [addPreference, setAddPreference] = useState("");
  dispatch(setAdditionalPreference(addPreference));
  const { service, postcode } = useParams();
  const { queryParams } = useParams();
  const {
    CleaningFrequency,
    StartingDate,
    BookingTime,
    PropertyType,
    HoursperSession,
  } = useSelector((state) => state.cleaning);
  const { ServiceName, Postcode } = useSelector((state) => state.searchbar);
  console.log(addPreference);
  return (
    <div className="w-full h-full">
      <Header />
      <div className="w-full h-full flex booking-req-container">
        <div className="bg-white h-full booking-req-white-box">
          <div className="flex booking-req-header text-sm ml-2 mr-1">
            <div className="flex mt-10">
              <CustomText
                label="Cleaning Requirements"
                labelStyle="font-semibold  text-black"
                underlineStyle="border-t border-black border-solid border-2 my-2 w-44 ml-0"
              />
            </div>
            <div className="flex booking-cnf-active">
              <CustomText
                label="Booking Confirmation"
                labelStyle="font-semibold  text-teal-500"
                underlineStyle="border-t border-teal-500 border-solid border-2 my-2 w-44 ml-0"
              />
            </div>
            <div className="flex booking-req-inactive">
              <CustomText
                label="Your Details"
                labelStyle="  text-slate-400"
                underlineStyle="border-t border-slate-400 border-solid border-2 my-2 w-44 ml-0"
              />
            </div>
          </div>
          <RenderIf condition={ServiceName === "Professional Cleaning"}>
            <ProfessionalCleaning />
          </RenderIf>
          <RenderIf condition={ServiceName === "Deep Cleaning"}>
            <DeepCleaning />
          </RenderIf>
          <RenderIf condition={ServiceName === "Handyman"}>
            <Handyman />
          </RenderIf>
          <RenderIf condition={ServiceName === "Moving"}>
            <Moving />
          </RenderIf>
          <RenderIf condition={ServiceName === "Painting"}>
            <Painting />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Outdoor Decking Repair & Polishing"}
          >
            <OutdoorDecking />
          </RenderIf>
          <RenderIf condition={ServiceName === "Plumbing"}>
            <Plumbing />
          </RenderIf>
          <RenderIf condition={ServiceName === "Marble Repairing & Polishing"}>
            <MarbleRepair />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Timber / Parquet Repair & Polishing"}
          >
            <TimberParquet />
          </RenderIf>
          <RenderIf condition={ServiceName === "Electrician"}>
            <Electrician />
          </RenderIf>
          <RenderIf condition={ServiceName === "Carpentry"}>
            <Carpentry />
          </RenderIf>
          <RenderIf condition={ServiceName === "Floor Repairing & Polishing"}>
            <FloorRepair />
          </RenderIf>
        </div>
        <div className="bg-slate-200 h-full booking-req-gray-box">
          <RenderIf condition={ServiceName === "Professional Cleaning"}>
            <ProfessionalCleaningDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Deep Cleaning"}>
            <DeepCleaningDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Handyman"}>
            <HandymanDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Moving"}>
            <MovingDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Painting"}>
            <PaintingDetails />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Outdoor Decking Repair & Polishing"}
          >
            <OutdoorDeckingDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Plumbing"}>
            <PlumbingDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Marble Repairing & Polishing"}>
            <MarbleRepairDetails />
          </RenderIf>
          <RenderIf
            condition={ServiceName === "Timber / Parquet Repair & Polishing"}
          >
            <TimberParquetDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Electrician"}>
            <ElectricianDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Carpentry"}>
            <CarpentryDetails />
          </RenderIf>
          <RenderIf condition={ServiceName === "Floor Repairing & Polishing"}>
            <FloorRepairDetails />
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
