import React, { useState } from 'react';

const DropdownExample = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const selectOption = (option) => {
    alert("Selected option: " + option);
    // Additional logic for handling the selected option can be added here
    setDropdownVisible(false); // Close the dropdown after an option is selected
  };

  return (
    <div className="relative">

      {/* Dropdown Trigger */}
      <div
        id="dropdownArrow"
        onClick={toggleDropdown}
        className="cursor-pointer"
      >
        &#9660; {/* Unicode for down arrow */}
      </div>

      {/* Dropdown Content */}
      <div
        id="dropdown"
        className={`${
          dropdownVisible ? 'block' : 'hidden'
        } absolute top-full left-0 mt-2 w-48 bg-white border rounded-md shadow-md`}
      >
        <div
          className="p-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => selectOption('Option 1')}
        >
          Option 1
        </div>
        <div
          className="p-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => selectOption('Option 2')}
        >
          Option 2
        </div>
        <div
          className="p-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => selectOption('Option 3')}
        >
          Option 3
        </div>
      </div>

      {/* Content Below Dropdown */}
      <div className="mt-8 p-4 bg-gray-200">
        {/* Content below the dropdown */}
        This is the content below the dropdown.
      </div>
    </div>
  );
};

export default DropdownExample;