import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  HavePets: "",
};

export const petsSlice = createSlice({
  name: "pets",
  initialState,
  reducers: {
    setHavePets(state, action) {
      state.HavePets = action.payload;
    },
  },
});

// Export action creators
export const { setHavePets } = petsSlice.actions;

// Export the reducer
export default petsSlice.reducer;
