import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  TotalPrice: "",
};

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setTotalPrice(state, action) {
      state.TotalPrice = action.payload;
    },
  },
});

// Export action creators
export const { setTotalPrice } = pricingSlice.actions;

// Export the reducer
export default pricingSlice.reducer;
