import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PropertyType: "",
};

export const propertyTypeSlice = createSlice({
  name: "propertyType",
  initialState,
  reducers: {
    setPropertyType(state, action) {
      state.PropertyType = action.payload;
    },
  },
});

// Export action creators
export const { setPropertyType } = propertyTypeSlice.actions;

// Export the reducer
export default propertyTypeSlice.reducer;
