import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  ServiceName: "",
  Postcode: "",
};

export const searchbarSlice = createSlice({
  name: "searchbar",
  initialState,
  reducers: {
    setServiceName(state, action) {
      state.ServiceName = action.payload;
    },
    setPostCode(state, action) {
      state.Postcode = action.payload;
    },
  },
});

// Export action creators
export const { setServiceName, setPostCode } = searchbarSlice.actions;

// Export the reducer
export default searchbarSlice.reducer;
