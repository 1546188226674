import React from "react";
import ImageBox from "./ImageBox";
import bgimage from "../../../assets/images/img001.png";
import CustomButton from "../../sharedComponents/CustomButton/CustomButton";
import Button from "../../sharedComponents/Button/Button";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";

export const Aboutus = () => {
  return (
    <div className="w-full flex flex-col h-full bg-slate-50 pb-10">
      {/* <div className=" relative flex bg-emerald-500 w-[200px] h-[200px]"></div> */}
      <div className="flex w-full justify-center text-center mt-2">
        <CustomText
          label="About us"
          styles="font-extrabold uppercase text-gray-700 aboutus-header"
          underlineStyle="border-t border-teal-500 border-solid aboutus-header-underline"
        />
      </div>
      <div className="flex aboutus-content">
        <div className="flex mt-10 aboutus-text">
          <CustomText
            label="We make life easier for you by bringing a plethora of services to your doorstep, from cleaning, plumbing to repairs and more.
              With our tech-savvy platform, you can schedule your services at your convenience, and we'll make sure our top-notch professionals show up and deliver quality service.
               We're not just empowering our customers, but also our hand-picked service partners, by providing them with the best tools, training, and support.
                 APAC Engineers ensures services are delivered like never before."
            styles="font-medium aboutus-text"
            labelStyle="font-medium"
          />
        </div>
        <div className="flex mt-10 justify-center">
          <ImageBox bgImage={bgimage} styles="aboutus-image" />
        </div>
      </div>
      <div>
        <Button label="SERVICES" styles="aboutus-button" />
      </div>
    </div>
  );
};
