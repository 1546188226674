import React from "react";
import ServiceOptions from "../pageComponents/Home/ServiceOptions";
import logo from "../../assets/icons/icon1.png";
import Stack from "@mui/material/Stack";
import CustomButton from "./CustomButton/CustomButton";
import Button from "./Button/Button";
import { CustomText } from "./CustomText/CustomText";
export default function Services() {
  return (
    <div className="background w-full h-full pb-4">
      <div className="mt-2 pl-30 w-full h-full bg-white">
        <div className="flex w-full justify-center text-center mt-2">
          <CustomText
            label="Services"
            styles="font-extrabold uppercase text-gray-700 aboutus-header"
            underlineStyle="border-t border-teal-500 border-solid aboutus-header-underline"
          />
        </div>
        <div className="services-stack">
          <Stack className="mt-2" direction="row">
            <ServiceOptions
              label="Professional Cleaning"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label "
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Get spotless homes!`}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Deep Cleaning"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Premium full home cleaning`}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Painting"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Perfect finish painting `}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Plumbing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Plumbing made easy! `}
              contentStyle="service-service-options-content"
            />
          </Stack>
          <Stack className="mt-2" direction="row">
            <ServiceOptions
              label="Electrician"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Flawless electrical repairs`}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Carpentry"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Master carpenters at your service`}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Handyman"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Skilled hero for your home `}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Moving"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Hassle-free moving services `}
              contentStyle="service-service-options-content"
            />
          </Stack>
          <Stack className="mt-2" direction="row">
            <ServiceOptions
              label="Floor Repairing & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Top-notch repair services `}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Outdoor decking Repair & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Repairs, Power Washing & more`}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Timber / Parquet Repair & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Low-cost repair solutions`}
              contentStyle="service-service-options-content"
            />
            <ServiceOptions
              label="Marble Repairing & Polishing"
              labelStyles="text-black hover:text-emerald-500 service-service-options-label"
              imageUrl={logo}
              imageStyle="service-service-options-image"
              styles="service-service-options-style"
              content={`Transform marble floors & more`}
              contentStyle="service-service-options-content"
            />
          </Stack>
          <div className="services-help-button">
            <Button
              styles="body rounded-md border border-teal-500 text-teal-500  rounded focus:outline-none focus:border-blue-700 hover:bg-teal-500 hover:text-white services-button"
              label="I Need Help"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
