import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import FrequencyBox from "../Booking/FrequencyBox";
import DateTimeBox from "../Booking/DateTimeBox";
import Button from "../../sharedComponents/Button/Button";
import { DropdownBox } from "../Booking/DropdownBox";
import {
  setCleaningFrequency,
  setHoursperSession,
} from "../../../constants/Redux/Slice/cleaningSlice";
import { setTotalPrice } from "../../../constants/Redux/Slice/pricingSlice";

export const ProfessionalCleaning = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ServiceName, Postcode } = useSelector((state) => state.searchbar);
  console.log("service name = ", ServiceName);
  console.log("post code = ", Postcode);
  const {
    CleaningFrequency,
    StartingDate,
    BookingTime,
    PropertyType,
    HoursperSession,
  } = useSelector((state) => state.cleaning);
  const { TotalPrice } = useSelector((state) => state.pricing);
  console.log("cleaning frequency = ", CleaningFrequency);
  console.log("hours per session = ", HoursperSession);
  console.log("Starting date = ", StartingDate);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedHours, setSelectedHours] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const { service, postcode } = useParams();

  const handleFrequency = (frequency) => {
    if (selectedFrequency === frequency) {
      setSelectedFrequency(null);
      // dispatch(setCleaningFrequency(selectedFrequency));
    } else {
      setSelectedFrequency(frequency);
    }
  };
  useEffect(() => {
    dispatch(setCleaningFrequency(selectedFrequency));
  }, [selectedFrequency]);

  const handleSelectHours = (hours) => {
    if (selectedHours === hours) {
      setSelectedHours(null);
    } else {
      setSelectedHours(hours);
      dispatch(setTotalPrice(hours * 25));
    }
  };
  useEffect(() => {
    dispatch(setHoursperSession(selectedHours));
  }, [selectedHours]);
  return (
    <div>
      <div>
        <CustomText
          label="Cleaning Frequency"
          labelStyle="font-medium  mt-10 booking-req-sub-header"
          styles=" mt-10 ml-1 mr-1"
        />
      </div>
      <div className="flex flex-row mt-2 frequency-box-container">
        <FrequencyBox
          label="One Time"
          labelStyles=" font-bold frequency-box-label"
          //priceLabel="SG $25/Hr"
          line1="Basic Cleaning"
          buttonStyles="outline outline-1 outline-black frequency-button"
          boxStyle={`${
            selectedFrequency === "One Time" ? "bg-teal-300" : ""
          } frequency-box`}
          onClick={() => handleFrequency("One Time")}
        />
        <FrequencyBox
          label="Weekly"
          labelStyles=" font-bold frequency-box-label"
          //priceLabel="SG $25/Hr"
          line1="Save time to book"
          line2="Same cleaner"
          lineStyles="space-y-2"
          buttonStyles="outline outline-1 outline-black"
          boxStyle={`${
            selectedFrequency === "Weekly" ? "bg-teal-300" : ""
          } frequency-box`}
          onClick={() => handleFrequency("Weekly")}
        />
        <FrequencyBox
          label="Fortnightly"
          labelStyles=" font-bold frequency-box-label"
          //priceLabel="SG $25/Hr"
          line1="Save time to book"
          line2="Same cleaner"
          lineStyles="space-y-2"
          buttonStyles="outline outline-1 outline-black"
          boxStyle={`${
            selectedFrequency === "Fortnightly" ? "bg-teal-300" : ""
          } frequency-box`}
          onClick={() => handleFrequency("Fortnightly")}
        />
      </div>
      <div>
        <CustomText
          label="Starting date & time"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-20 ml-1 mr-1"
        />
      </div>

      <div>
        <DateTimeBox
          label="Select date & time"
          boxStyle=" mt-2 ml-1 mr-1 booking-req-dropdown"
        />
      </div>

      <div>
        <CustomText
          label="About your property"
          labelStyle="font-medium  mt-10 booking-req-sub-header"
          styles="font-medium  mt-10 ml-1 mr-1"
        />
      </div>
      <div>
        <CustomText
          label="Type of property"
          labelStyle="font-normal  mt-5 booking-req-sub-header2"
          styles="font-normal  mt-2 ml-1 mr-1"
        />
      </div>
      <div>
        <DropdownBox
          label="Select property type"
          boxStyle=" h-12 mt-2 ml-1 mr-1 booking-req-dropdown"
          buttonStyle=" h-12"
          selectedService="Professional Cleaning"
          type="normal"
        />
      </div>
      <div>
        <CustomText
          label="How many hours do you need ?"
          labelStyle="font-medium booking-req-sub-header "
          styles="font-medium mt-10 ml-1 mr-1"
        />
      </div>
      <div>
        <CustomText
          label="We recommend selecting 3 hours ."
          labelStyle="font-normal  mt-5 ml-1 mr-1 booking-req-sub-header2"
          styles="font-normal mt-2"
        />
      </div>
      <div className="flex flex-row flex-wrap mt-3 space-x-2 ml-10 mr-1">
        <Button
          label="2.5 hours"
          styles={`h-12 w-20 rounded-[10px] outline outline-offset-1 outline-slate-300 pl-1 pr-1 mt-2 ${
            selectedHours === "2.5 hours"
              ? "bg-teal-500"
              : "hover:outline-black hover:bg-teal-500"
          }`}
          onClick={() => handleSelectHours("2.5")}
        />
        <Button
          label="3 hours"
          styles={`h-12 w-20 rounded-[10px] outline outline-offset-1 outline-slate-300 pl-1 pr-1 mt-2 ${
            selectedHours === "3 hours"
              ? "bg-teal-500"
              : "hover:outline-black hover:bg-teal-500"
          }`}
          onClick={() => handleSelectHours("3")}
        />
        <Button
          label="3.5 hours"
          styles={`h-12 w-20 rounded-[10px] outline outline-offset-1 outline-slate-300 pl-1 pr-1 mt-2 ${
            selectedHours === "3.5 hours"
              ? "bg-teal-500"
              : "hover:outline-black hover:bg-teal-500"
          }`}
          onClick={() => handleSelectHours("3.5")}
        />
        <Button
          label="4 hours"
          styles={`h-12 w-20 rounded-[10px] outline outline-offset-1 outline-slate-300 pl-1 pr-1 mt-2 ${
            selectedHours === "4 hours"
              ? "bg-teal-500"
              : "hover:outline-black hover:bg-teal-500"
          }`}
          onClick={() => handleSelectHours("4")}
        />
        <Button
          label="More"
          styles={`h-12 w-20 rounded-[10px] outline outline-offset-1 outline-slate-300 pl-1 pr-1 mt-2 ${
            selectedHours === "More"
              ? "bg-teal-500"
              : "hover:outline-black hover:bg-teal-500"
          }`}
          onClick={() => handleSelectHours("More")}
        />
      </div>
    </div>
  );
};
