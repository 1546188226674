import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  BookingDate: "",
  BookingTime: "",
};

export const dateTimeSlice = createSlice({
  name: "dateTime",
  initialState,
  reducers: {
    setBookingDate(state, action) {
      state.BookingDate = action.payload;
    },
    setBookingTime(state, action) {
      state.BookingTime = action.payload;
    },
  },
});

export const { setBookingDate, setBookingTime } = dateTimeSlice.actions;

export default dateTimeSlice.reducer;
