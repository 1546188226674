import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CleaningFrequency: "",
  StartingDate: "",
  BookingTime: "",
  PropertyType: "",
  HoursperSession: "",
};

export const cleaningSlice = createSlice({
  name: "cleaning",
  initialState,
  reducers: {
    setCleaningFrequency(state, action) {
      state.CleaningFrequency = action.payload;
    },
    setStartingDate(state, action) {
      state.StartingDate = action.payload;
    },
    setBookingTime(state, action) {
      state.BookingTime = action.payload;
    },
    setPropertyType(state, action) {
      state.PropertyType = action.payload;
    },
    setHoursperSession(state, action) {
      state.HoursperSession = action.payload;
    },
  },
});

// Export action creators
export const {
  setCleaningFrequency,
  setStartingDate,
  setBookingTime,
  setPropertyType,
  setHoursperSession,
} = cleaningSlice.actions;

// Export the reducer
export default cleaningSlice.reducer;
