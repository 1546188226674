import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHavePets } from "../../../constants/Redux/Slice/petsSlice";
import { setPropertySize } from "../../../constants/Redux/Slice/deepCleaningSlice";

export const CustomDropdown = ({
  label,
  boxStyle,
  buttonStyle,
  optionType,
}) => {
  const [isOpen, setISOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch or generate options
    const newOptions = getOptionsBasedOnService(optionType);

    setOptions(newOptions);
  }, [optionType]);

  const getOptionsBasedOnService = (optionType) => {
    if (optionType === "Have Pets") {
      return ["Yes i have pets", "No i don't have"];
    } else if (optionType === "Property size") {
      return [
        "500-700 sqft",
        "700-800 sqft",
        "800-1000 sqft",
        "1000-1200 sqft",
        "1200-1400 sqft",
        "1400-1600 sqft",
        "1600-1800 sqft",
        "1800-2000 sqft",
      ];
    }
    return [];
  };

  const handleOptionSelect = (option, optionType) => {
    switch (optionType) {
      case "Have Pets":
        console.log("in case pets");
        console.log("this is option", option);
        dispatch(setHavePets(option));
        break;
      case "Property size":
        dispatch(setPropertySize(option));
        console.log("in case size");
        console.log("this is option", option);
        break;
      default:
        console.log("No options", optionType);
    }

    setSelectedOption(option);
    setISOpen(false);
  };

  return (
    <div
      className={`flex flex-row items-center justify-items-center py-[2px]  bg-white rounded-[10px] ${boxStyle}`}
    >
      <div>
        <button
          className={`flex bg-white items-center justify-between rounded-[10px] border-2 border-slate-400  active:text-white ${buttonStyle}`}
          onClick={() => setISOpen((prev) => !prev)}
        >
          <p className="ml-10 text-slate-400">{selectedOption || label}</p>
          {!isOpen ? <ArrowDropDown /> : <ArrowDropUp />}
        </button>
        {isOpen && (
          <div className="absolute bg-white mt-1 items-center p-2 rounded-[10px] shadow-lg">
            {options.map((option) => (
              <div
                key={option}
                className={`cursor-pointer py-1 hover:bg-teal-500 rounded-[10px] ${
                  selectedOption === option ? "bg-teal-200" : ""
                }`}
                onClick={() => handleOptionSelect(option, optionType)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
