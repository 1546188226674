import React from "react";
import Button from "../../sharedComponents/Button/Button";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";

const FrequencyBox = ({
  customComponent,
  label,
  labelStyles,
  priceLabel,
  line1,
  line2,
  lineStyles,
  buttonStyles,
  onClick,
  boxStyle,
}) => {
  return (
    <div
      className={`flex flex-col rounded-[10px] pb-2 shadow-2xl border-2 space-y-5 border-slate-400 text-center hover:bg-teal-200 ${boxStyle}`}
    >
      <div className="mt-2">
        <CustomText label={label} styles={labelStyles} />
      </div>
      <div className=" font-semibold">{priceLabel}</div>
      <div className=" font-medium">
        <ul className={`${lineStyles}`}>
          <li>{line1}</li>
          <li>{line2}</li>
        </ul>
      </div>
      <div className="mt-auto">
        <Button
          label="Select"
          styles={`rounded-[5px] hover:bg-white pb-2 pr-4 pl-4 pt-2 ${buttonStyles}`}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default FrequencyBox;
