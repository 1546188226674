import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import DateTimeBox from "../Booking/DateTimeBox";
import { setServiceDetails } from "../../../constants/Redux/Slice/serviceDetailsSlice";

export const MarbleRepair = () => {
  const [details, setDetails] = useState("");
  const disptach = useDispatch();
  disptach(setServiceDetails(details));
  return (
    <div>
      <div>
        <CustomText
          label="Booking date & time"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-20 ml-1 mr-1"
        />
      </div>

      <div>
        <DateTimeBox
          label="Select date & time"
          boxStyle=" mt-2 ml-1 mr-1 booking-req-dropdown"
        />
      </div>
      <div>
        <CustomText
          label="Please tell us what type of service you want"
          labelStyle="font-normal booking-req-sub-header ml-20 "
          styles="mt-5"
        />
      </div>
      <input
        type="text"
        className="ml-20 mt-8  h-[120px] outline outline-2 w-[250px] outline-slate-400 rounded-[10px] justify-start booking-req-dropdown"
        placeholder="Please type here ..."
        style={{ textIndent: "20px", paddingBottom: "40px" }}
        value={details}
        onChange={(e) => setDetails(e.target.value)}
      />
    </div>
  );
};
