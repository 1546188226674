import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateTimeBox from "../Booking/DateTimeBox";
import { CustomText } from "../../sharedComponents/CustomText/CustomText";
import { setSelectedServices } from "../../../constants/Redux/Slice/handymanSlice";

export const Handyman = () => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const dispatch = useDispatch();

  const buttons = [
    { id: 1, label: "Door" },
    { id: 2, label: "Sink" },
    { id: 3, label: "Water tap/hose" },
    { id: 4, label: "Light" },
    { id: 5, label: "Tiles" },
    { id: 6, label: "Power point/Switch" },
  ];
  useEffect(() => {
    dispatch(setSelectedServices(selectedButtons));
  }, [selectedButtons]);

  const handleButtonClick = (buttonId) => {
    const selectedLabel = buttons.find(
      (button) => button.id === buttonId
    )?.label;

    setSelectedButtons((prevSelectedButtons) => {
      if (prevSelectedButtons.includes(selectedLabel)) {
        return prevSelectedButtons.filter((label) => label !== selectedLabel);
      } else {
        return [...prevSelectedButtons, selectedLabel];
      }
    });
  };
  return (
    <div>
      <div>
        <CustomText
          label="Starting date & time"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-20 ml-1 mr-1"
        />
      </div>

      <div>
        <DateTimeBox
          label="Select date & time"
          boxStyle=" mt-2 ml-1 mr-1 booking-req-dropdown"
        />
      </div>
      <div>
        <CustomText
          label="What would you like to service?"
          labelStyle="font-medium  mt-20 booking-req-sub-header"
          styles="font-medium mt-20 ml-1 mr-1"
        />
      </div>
      <div className="ml-5 mt-2">
        {buttons.map((button) => (
          <button
            key={button.id}
            onClick={() => handleButtonClick(button.id)}
            className={`button1 ${
              selectedButtons.includes(button.id) ? "selected" : ""
            }`}
          >
            {button.label}
          </button>
        ))}
        <p className="mt-5 ml-5">
          Selected Services: {selectedButtons.join(" , ") || "None"}
        </p>
      </div>
    </div>
  );
};
